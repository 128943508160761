import axios from "axios";

export const loadClient = (id, callback) => {
    const config = {
        url: `/clients/${id}`
    }

    axios.request(config).then(
        response => {
            callback( response.data);
        }
    )
}

export const saveClient = (data, callback) => {
    const config = {
        url: data.id ? `/clients/${data.id}` : '/clients',
        method: data.id ? 'PUT' : 'POST',
        data: {
            client: data
        }
    }

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const deleteUser = (id, callback) => {
    const config = {
        url: `/users/${id}`,
        method: 'DELETE'
    }

    axios.request(config).then(
        response => {
            callback();
        }
    )
}