import React, {createContext, useContext, useEffect, useState} from 'react';
import * as actions from "./AuthContextActions";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const isAuthenticated = !!localStorage.getItem('token')

    useEffect(() => {
        if (isAuthenticated) {
            const role = localStorage.getItem('role');

            setUser({role})
        }
    }, []);

    const login = (credentials, onError) => {
        actions.login(
            credentials,
            (data, token) => {
                localStorage.setItem('token', token);
                localStorage.setItem('role', data.role);
                localStorage.setItem('username', data.username);

                const userData = { role: data.role };
                setUser(userData);
            },
            onError
        );
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('username');
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
