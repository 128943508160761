import axios from "axios";

export const loadJobs = (params, callback) => {
    const config = {
        url: '/jobs',
        params: params
    };

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const loadProfile = (callback) => {
    const config = {
        url: `/profile/user`
    }

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )

}

export const loadGroups = (params, callback) => {
    const config = {
        url: '/groups',
        params: params
    };

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const loadClients = (params, callback) => {
    const config = {
        url: '/clients',
        params: params
    };


    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}
