import React, {createContext, useContext, useState} from 'react';
import * as actions from './ContextProviderActions';

export const Context = createContext();

export const ContextProvider = ({children}) => {
    const [jobs, setJobs] = useState([]);
    const [groups, setGroups] = useState([]);
    const [clients, setClients] = useState([]);
    const [profile, setProfile] = useState();
    const role = localStorage.getItem('role');

    const reloadProfile = (callback) => {
        actions.loadProfile((data) => {
            setProfile(data);
            callback && callback(data);
        });
    };

    const reloadJobs = (callback) => {
        if (role === 'admin' || role === 'user') {
            actions.loadJobs({}, (data) => {
                setJobs(data);
                callback && callback(data);
            });
        }
    }

    const reloadGroups = (callback) => {
        if (role === 'admin') {
            actions.loadGroups({}, (data) => {
                setJobs(data);
                callback && callback(data);
            });
        }
    }

    const reloadClients = (callback) => {
        if (role === 'superadmin') {
            actions.loadClients({}, (data) => {
                setClients(data);
                callback && callback(data);
            });
        }
    }

    return (
        <Context.Provider value={{
            jobs, setJobs, reloadJobs,
            profile, setProfile, reloadProfile, groups, setGroups, reloadGroups,
            clients, setClients, reloadClients
        }}>
            {children}
        </Context.Provider>
    );
}

export const useContextProvider = () => {
    return useContext(Context);
}