import axios from "axios";

export const saveGroup = (data, callback) => {
    const config = {
        url: data.id ? `/groups/${data.id}` : '/groups',
        method: data.id ? 'PUT' : 'POST',
        data: {
            group: data
        }
    }

    axios.request(config).then(
        response => {
            callback && callback(response.data);
        }
    )
}

export const loadGroup = (id, callback) => {
    const config = {
        url: `/groups/${id}`
    }

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}
