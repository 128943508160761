import React, {useEffect, useMemo, useState} from 'react';
import "./Jobs.scss"
import * as actions from "./JobsActions"
import {useNavigate} from "react-router-dom";
import Button from "../../common/buttons/Button";
import Icon, {eyeIcon, jobIcon} from "../../common/icons/Icon";
import Table from "../../common/tables/Table";
import CardHeader from "../../containers/card/CardHeader";
import Card from "../../containers/card/Card";
import CardBody from "../../containers/card/CardBody";
import TableActions from "../../common/buttons/TableActions";
import {useContextProvider} from "../../context/ContextProvider/ContextProvider";
import TokensModal from "../../common/modals/TokensModal";

const Jobs = () => {
    const navigate = useNavigate();
    const [tokensToShow, setTokensToShow] = useState([]);
    const role = localStorage.getItem('role');
    const [loading, setLoading] = useState(true);
    const {jobs, setJobs} = useContextProvider();

    const toggleTokenVisibility = (id, tokens) => {
        const tokenGroups = tokens.map(token => ({
            group_name: token.group_name,
            api_token: token.api_token
        }));
        setTokensToShow(tokenGroups);
    };

    const refreshList = () => {
        actions.loadJobs({}, (data) => {
            setJobs(data);
            setLoading(false);
        });
    }

    const deleteAction = (job) => {
        actions.deleteJob(job.slug, () => {
            refreshList();
        });
    }

    const editAction = (job) => {
        navigate(`/job/${job.slug}`)
    }

    const handleAddJob = () => {
        navigate(`/job`)
    }

    useEffect(() => {
        refreshList();
    }, []);

    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({row}) => (
                <div className='row-name'>
                    <p>{row.original.name}</p>
                </div>)
        },
        {
            Header: 'Api token',
            accessor: 'api_token',
            Cell: ({row}) => (
                <div className='token-row'>
                    {row.original.api_tokens[0] &&
                        <Button size='icon' onClick={() => toggleTokenVisibility(row.original.id, row.original.api_tokens)}>
                            <Icon icon={eyeIcon} />
                        </Button>}
                </div>
            )
        },
        {
            Header: 'Jenkins job url',
            accessor: 'jenkins_job_url'
        },
        {
            Header: role === 'admin' ? 'Options' : 'Hidden',
            Cell: ({row}) => (
                <TableActions row={row.original} name='Job' onEdit={editAction} onDelete={deleteAction}/>
            )
        }
    ], []);

    return (
        <Card className="jobs">
            <CardHeader onAdd={role === 'admin' && handleAddJob} icon={jobIcon}/>
            <CardBody>
                <Table columns={columns} data={jobs} loading={loading}/>
            </CardBody>

            <TokensModal
                isOpen={tokensToShow.length > 0}
                onClose={() => setTokensToShow([])}
                tokens={tokensToShow}
            />
        </Card>
    );
}

export default Jobs;
