import React, { useEffect, useMemo, useState } from 'react';
import "./Settings.scss";
import * as actions from "./SettingsActions";
import { useNavigate } from "react-router-dom";
import { settingsIcon } from "../../common/icons/Icon";
import Table from "../../common/tables/Table";
import CardHeader from "../../containers/card/CardHeader";
import Card from "../../containers/card/Card";
import CardBody from "../../containers/card/CardBody";
import TableActions from "../../common/buttons/TableActions";
import { useContextProvider } from "../../context/ContextProvider/ContextProvider";
import TabFilter from "../../common/tab/TabFilter";

const Settings = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { groups, setGroups } = useContextProvider();
    const [tab, setTab] = useState("Groups")
    const tabs = [
        { name: "Groups" },
    ];

    const role = localStorage.getItem('role');


    const refreshList = () => {
        actions.loadGroups({}, (data) => {
            setGroups(data);
            setLoading(false);
        });
    };


    const deleteAction = (group) => {
        actions.deleteGroup(group.id, () => {
            refreshList();
        });
    };

    const editAction = (group) => {
        navigate(`group/${group.id}`);
    };

    useEffect(() => {
        refreshList();
    }, []);

    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ row }) => (
                <div className='row-name'>
                    <p>{row.original.name}</p>
                </div>
            )
        },
        {
            Header: 'Options',
            Cell: ({ row }) => (
                role === 'admin' &&
                <TableActions row={row.original} name='Group' onEdit={editAction} onDelete={deleteAction} />
            )
        }
    ], []);

    return (
        <Card className="settings">
            <CardHeader icon={settingsIcon} >
                <TabFilter setInitialTab={setTab} initialTab={tab} tabs={tabs} />
            </CardHeader>
            <CardBody>
                <Table columns={columns} data={groups} loading={loading} />
            </CardBody>
        </Card>
    );
};

export default Settings;
