import React, {useEffect, useMemo, useState} from 'react';
import "./Users.scss"
import * as actions from "./UsersActions"
import {useNavigate} from "react-router-dom";
import {userGroupIcon} from "../../common/icons/Icon";
import Table from "../../common/tables/Table";
import CardHeader from "../../containers/card/CardHeader";
import Card from "../../containers/card/Card";
import TableActions from "../../common/buttons/TableActions";
import {selectStyles} from "../../common/styles/selectStyles";
import CreatableSelect from "react-select/creatable";

const Users = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [groupUsers, setGroupUsers] = useState({});
    const role = localStorage.getItem('role');

    const refreshList = () => {
        actions.loadUsers({}, (data) => {
            setUsers(data);
            setLoading(false);

            const userGroups = data.reduce((acc, user) => {
                acc[user.id] = user.group_users.map(groupUser => ({
                    id: groupUser.id,
                    group_id: groupUser.group.id,
                    name: groupUser.group.name
                }));
                return acc;
            }, {});

            setGroupUsers(userGroups);
        });
        {role === 'admin' &&
            actions.loadGroups({}, (data) => {
                setGroups(data);
            });
        }
    }

    const deleteAction = (user) => {
        actions.deleteUser(user.id, () => refreshList());
    }

    const editAction = (user) => {
        navigate(`/user/${user.id}`)
    }

    const handleAddUser = () => {
        navigate(`/user`)
    }

    useEffect(() => {
        refreshList();
    }, []);

    const handleChange = (selectedOptions, userId) => {
        const updatedGroupUsers = { ...groupUsers };

        const newValue = groupUsers[userId].map(v => ({
            ...v,
            _destroy: !selectedOptions.some(option => option.value === v.id)
        }));

        const newSelected = selectedOptions
            .filter(option => !newValue.some(v => v.id === option.value))
            .map(toFormMapper);

        updatedGroupUsers[userId] = [...newValue, ...newSelected];

        setGroupUsers(updatedGroupUsers);

        const userGroupAttributes = [...newValue, ...newSelected].map(group => ({
            id: group.id,
            group_id: group.group_id,
            _destroy: group._destroy
        }));
        actions.saveUser({ id: userId, group_users_attributes: userGroupAttributes }, refreshList);
    };

    const handleCreateOption = (newGroupName, userId) => {
        actions.saveGroup({ name: newGroupName }, (newGroup) => {
            setGroups(prevGroups => [
                ...prevGroups,
                { id: newGroup.id, name: newGroup.name }
            ]);

            setGroupUsers(prevGroupUsers => {
                const updatedGroupUsers = { ...prevGroupUsers };

                if (updatedGroupUsers[userId]) {
                    updatedGroupUsers[userId] = [
                        ...updatedGroupUsers[userId],
                        { group_id: newGroup.id, name: newGroup.name }
                    ];
                }
                return updatedGroupUsers;
            });

            const userGroupAttributes = [
                ...groupUsers[userId].map(group => ({
                    id: group.id,
                    group_id: group.group_id,
                    _destroy: group._destroy
                })),
                { group_id: newGroup.id, name: newGroup.name }
            ];

            actions.saveUser({ id: userId, group_users_attributes: userGroupAttributes }, () => {
                refreshList();
            });
        });
    };

    const optionsMapper = (option) => ({ value: option.id, label: option.name });

    const groupMapper = (userId) => {
        return groupUsers[userId]?.filter(groupUser => !groupUser._destroy).map(groupUser => ({
            value: groupUser.id,
            label: groupUser.name
        })) || [];
    };

    const toFormMapper = (value) => {
        return {
            group_id: value.value,
            name: value.label
        };
    };

    const filterAvailableGroups = (userId) => {
        const existingGroupIds = groupUsers[userId]?.map(group => group.group_id) || [];
        return groups.filter(group => !existingGroupIds.includes(group.id));
    };

    const columns = useMemo(() => [
        {
            Header: 'Username',
            accessor: 'username',
            Cell: ({row}) => (
                <div className='row-name'>
                    <p>{row.original.username}</p>
                </div>)
        },
        {
            Header: 'Jenkins Username',
            accessor: 'jenkins_username'
        },
        {
            Header: 'Jenkins Token',
            accessor: 'jenkins_token'
        },
        {
            Header: 'Role',
            accessor: 'role'
        },
        {
            Header: 'Group',
            Cell: ({row}) => (
                <div className='row-group'>
                    <CreatableSelect
                        id={`group-users-attributes-${row.original.id}`}
                        className="creatable-select"
                        type="creatable-select"
                        name={`group_users_attributes_${row.original.id}`}
                        value={groupMapper(row.original.id)}
                        onChange={(selectedOptions) => handleChange(selectedOptions, row.original.id)}
                        options={filterAvailableGroups(row.original.id).map(optionsMapper)}
                        onCreateOption={(newGroupName) => handleCreateOption(newGroupName, row.original.id)}
                        isMulti
                        styles={selectStyles}
                    />
                </div>)
        },
        {
            Header: 'Actions',
            Cell: ({row}) => (
                <TableActions row={row.original} name='User' onEdit={editAction} onDelete={deleteAction}/>
            )
        }
    ], [users, groupUsers, groups]);

    return (
        <Card className="users">
            <CardHeader onAdd={handleAddUser} icon={userGroupIcon}/>
            <Table columns={columns} data={users} loading={loading}/>
        </Card>
    );
}

export default Users;
