import React, {useEffect, useMemo, useState} from 'react';
import {Tooltip} from 'react-tooltip';
import "./Builds.scss"
import * as actions from "./BuildsActions"
import {useNavigate, useParams} from "react-router-dom";
import Table from "../../common/tables/Table";
import CardHeader from "../../containers/card/CardHeader";
import {formatDuration, formatRelativeDate, formatStatus, userInitials} from "../../common/utils/utils";
import Card from "../../containers/card/Card";
import {buildIcon} from "../../common/icons/Icon";
import TagsFilter from "./TagsFilter";
import {statusColors} from "../../common/utils/constants";
import TableActions from "../../common/buttons/TableActions";
import InfiniteScroll from "react-infinite-scroll-component";
import CardBody from "../../containers/card/CardBody";
import LazyImage from "../../common/./lazyImage/LazyImage";
import appConfig from "../../config/applicationConfiguration";

const Builds = () => {
    const {jobSlug, tag} = useParams();
    const navigate = useNavigate();
    const [builds, setBuilds] = useState([]);
    const [buildsStates, setBuildsStates] = useState({
        loading: true,
        loadingTags: true,
        jobSlug
    });
    const [tags, setTags] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const refreshList = () => {
        actions.loadBuilds(jobSlug,
            {
                tag: tag === "Untagged" ? null : tag
            },
            (data) => {
                setBuilds(data);
                setBuildsStates(prevState => ({...prevState, loading: false}));
                setHasMore(data.length === 30);
            });
    }

    const loadMore = () => {
        actions.loadBuilds(jobSlug,
            {
                lastId: builds.length > 0 && builds[builds.length - 1].id,
                tag: tag === "Untagged" ? null : tag
            },
            (data) => {
                setBuilds(prevBuilds => [...prevBuilds, ...data]);
                setBuildsStates(prevState => ({...prevState, loading: false}));
                setHasMore(data.length === 30);
            });
    }

    const showAction = (build) => {
        navigate(`/build/${jobSlug}/${tag}/${build.id}`)
    }

    const deleteAction = (build) => {
        actions.deleteBuild(jobSlug, build.id, () => {
            setBuilds(builds.filter(b => b.id !== build.id));
        });
    }

    useEffect(() => {
        setBuildsStates(prevState => ({...prevState, loadingTags: true}));
        actions.loadTags(jobSlug, (data) => {
            setTags([...data, {name: "Untagged", color: "#737373"}]);
            setBuildsStates(prevState => ({...prevState, loadingTags: false}));
        })
        setBuildsStates(prevState => ({...prevState, jobSlug}));

    }, [jobSlug]);

    useEffect(() => {
        if (tag) {
            setBuildsStates(prevState => ({...prevState, loading: true}));
            refreshList();
        }

    }, [jobSlug, tag]);


    const columns = useMemo(() => [
            {
                Header: '#',
                accessor: 'position',
                Cell: ({row}) => (
                    <div className='row-position'>
                        <div className='status'
                             style={{
                                 backgroundColor: statusColors[row.original.status],
                             }}
                             data-tooltip-id="status-tooltip"
                             data-tooltip-content={formatStatus(row.original.status)}
                             data-tooltip-place='left'
                        />
                        <div className='position'>{row.original.position}</div>
                    </div>)

            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({row}) => (
                    <div className='row-name'>
                        <p>{row.original.job.name}</p>
                    </div>)
            },
            {
                Header: 'Group',
                accessor: 'group',
                Cell: ({row}) => (
                    <>
                        {row.original.group &&
                            <div>
                                {row.original.group.name}
                            </div>
                        }
                    </>
                )

            },
            {
                Header: 'When',
                accessor: 'when',
                Cell: ({row}) => formatRelativeDate(row.original.when)
            },
            {
                Header: 'Took',
                accessor: 'took',
                Cell: ({row}) => formatDuration(row.original.took, row.original.took_avg)
            },
            {
                Header: 'Options',
                Cell: ({row}) => (
                    <TableActions row={row.original} name='Build' onShow={showAction} onDelete={deleteAction}/>

                )
            }
        ]
        , [builds]);


    return (
        <Card className="builds">
            <CardHeader icon={buildIcon}
                        loading={buildsStates.loading}
                        name={tag}
            >
                {!buildsStates.loadingTags && jobSlug === buildsStates.jobSlug && <TagsFilter
                    tags={tags}
                    setFilter={(filter) => {
                        if (filter !== tag) {
                            setBuilds([]);
                            navigate(`/builds/${jobSlug}/${filter}`);
                        }
                    }}
                    filter={tag}
                />}
            </CardHeader>
            <CardBody contentId="builds">
                <InfiniteScroll
                    next={loadMore}
                    hasMore={hasMore}
                    dataLength={builds.length}
                    loader={<></>}
                    style={{overflow: 'hidden', height: '100%'}}
                    scrollableTarget="builds"
                >
                    <Table columns={columns} data={builds} loading={buildsStates.loading}/>
                </InfiniteScroll>
            </CardBody>
            <Tooltip id="status-tooltip" className='builds-tooltip'/>
        </Card>
    );
}

export default Builds;
