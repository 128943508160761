import React from 'react';
import './Navbar.scss';
import {useLocation} from "react-router-dom";
import ProfileDropdown from "../dropdowns/ProfileDropdown";
import {jobIcon, userGroupIcon} from "../icons/Icon";
import BuildsDropdown from "../dropdowns/BuildsDropdown";
import NavbarItem from "./NavbarItem";


const Navbar = () => {
    const location = useLocation();
    const role = localStorage.getItem('role');

    const isSelected = (path) => {
        const splitPath = location.pathname.split('/');
        return path.includes(splitPath[1])
    }

    if (location.pathname === '/login') return <></>

    return (
        <div className="navbar">
            <div className='navigation'>
                <div className='name'>
                    picklepot
                </div>
                {role === "admin" &&
                    <NavbarItem icon={userGroupIcon} text="Users" path="/users" isSelected={isSelected}/>
                }
                {role === 'superadmin' &&
                    <NavbarItem icon={userGroupIcon} text="Clients" path="/clients" isSelected={isSelected}/>
                }
                {(role === 'admin' || role === 'user') &&
                    <>
                        <NavbarItem icon={jobIcon} text="Jobs" path="/jobs" isSelected={isSelected}/>
                        <BuildsDropdown isSelected={isSelected}/>
                    </>
                }
            </div>
            <div>
                <ProfileDropdown/>
            </div>
        </div>
    );
}

export default Navbar;
