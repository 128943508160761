import React, { useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from "react-router-dom";
import * as actions from "./UserActions";
import "./User.scss";
import CardHeader from "../../containers/card/CardHeader";
import _ from "lodash";
import Card from "../../containers/card/Card";
import { userGroupIcon } from "../../common/icons/Icon";
import Loader from "../../common/loader/Loader";
import FormField from "../../common/formField/FormField";

const validationSchema = Yup.object({
    id: Yup.string(),
    username: Yup.string().required('Username is required'),
    password: Yup.string().when('id', {
        is: id => id === undefined,
        then: schema => schema.min(6, 'Must be at least 6 characters').required('Required'),
        otherwise: schema => schema.min(6, 'Must be at least 6 characters')
    }),
    confirmPassword: Yup.string().when('id', {
        is: id => id === undefined,
        then: schema => schema.oneOf([Yup.ref('password'), null], 'Passwords must match'),
        otherwise: schema => schema.oneOf([Yup.ref('password'), null], 'Passwords must match')
    })
});


const User = () => {
    const {id, clientid} = useParams();
    const navigate = useNavigate();
    const role = localStorage.getItem('role');
    const [jenkinsToken, setJenkinsToken] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const initialValues = {
        username: '',
        password: '',
        confirmPassword: '',
        client_id: clientid
    };
    const roleOptions = [
        { value: "admin", label: "admin" },
        { value: "user", label: "user" },
    ];


    const saveUser = (values, callback) => {
        if (!values.change_password && values.id) {
            values = _.omit(values, ['password']);
        }

        if (!values.change_jenkins && values.id) {
            values = _.omit(values, ['jenkins_username', 'jenkins_token']);
        }

        values = _.omit(values, ['change_password', 'change_jenkins', 'confirmPassword']);

        actions.saveUser(values, () => {
            if (role === 'superadmin') {
                navigate(`/client/${clientid}`, { state: { tab: "Users" } });
            } else {
                navigate("/users");
            }
        });
    };

    const onCancel = () => {
        if (role === 'superadmin') {
            navigate(`/client/${clientid}`, { state: { tab: "Users" } });
        } else {
            navigate("/users");
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: saveUser
    });

    useEffect(() => {
        if (id) {
            setLoading(true)
            actions.loadUser(id, (data) => {
                formik.setValues(data);
                setJenkinsToken(data.jenkins_token);
                setLoading(false);
            });
        }
    }, [id]);

    const mapRoleToOption = (role) => {
        return roleOptions.find(option => option.value === role) || null;
    };

    const handleChangeSelect = (selectedOption) => {
        formik.setFieldValue("role", selectedOption.value);
    };

    return (
        <Card className='user'>
            <CardHeader
                name={formik.values.username}
                onCancel={onCancel}
                onSave={formik.handleSubmit}
                icon={userGroupIcon}
                loading={loading}
            />
            {loading ? <Loader/> :
                <form autoComplete="off">
                    <FormField
                        id="username-input"
                        label="Username"
                        name="username"
                        value={formik.values.username}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.username}
                        errors={formik.errors.username}

                    />
                    {formik.values.id &&
                        <FormField
                            id="change-password-input"
                            label='Change password'
                            type='checkbox'
                            name='change_password'
                            value={formik.values.change_password}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.change_password}
                            errors={formik.errors.change_password}

                        />
                    }
                    {(formik.values.change_password || !formik.values.id) &&
                        <>
                            <FormField
                                id="password-input"
                                label="Password"
                                type="password"
                                name="password"
                                autoComplete="new-password"
                                value={formik.values.password}
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                touched={formik.touched.password}
                                errors={formik.errors.password}
                            />
                            <FormField
                                id="confirm-password-input"
                                label="Confirm password"
                                type="password"
                                name="confirmPassword"
                                value={formik.values.confirmPassword}
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                touched={formik.touched.confirmPassword}
                                errors={formik.errors.confirmPassword}
                            />
                        </>}
                    {formik.values.id &&
                        <FormField
                            id="change-jenkins-input"
                            label="Change jenkins credentials"
                            type="checkbox"
                            name="change_jenkins"
                            value={formik.values.change_jenkins}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.change_jenkins}
                            errors={formik.errors.change_jenkins}
                        >
                            <span className="jenkins-token">{jenkinsToken}</span>
                        </FormField>
                    }
                    {(formik.values.change_jenkins || !formik.values.id) && <>
                        <FormField
                            id="jenkins-username-input"
                            label="Jenkins username"
                            name="jenkins_username"
                            value={formik.values.jenkins_username}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.jenkins_username}
                            errors={formik.errors.jenkins_username}
                        />
                        <FormField
                            id="jenkins-token-input"
                            label="Jenkins token"
                            name="jenkins_token"
                            value={formik.values.jenkins_token}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.jenkins_token}
                            errors={formik.errors.jenkins_token}
                        />
                    </>
                    }
                    {role === 'superadmin' && (
                        <FormField
                            id="role-input"
                            label="Role"
                            name="role"
                            value={mapRoleToOption(formik.values.role)}
                            handleChange={handleChangeSelect}
                            handleChangeSelect={handleChangeSelect}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.role}
                            errors={formik.errors.role}
                            type="select"
                            options={roleOptions}
                        />
                    )}
                </form>
            }
        </Card>
    );
}

export default User;
