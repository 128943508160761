import React, {memo} from "react";
import './CardHeader.scss';
import Button from "../../common/buttons/Button";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import CancelSaveButtons from "../../common/buttons/CancelSaveButtons";
import Icon, {breadcrumbArrowIcon} from "../../common/icons/Icon";
import {useContextProvider} from "../../context/ContextProvider/ContextProvider";
import classnames from "classnames";

const CardHeader = ({children, onAdd, onCancel, onSave, goBack, name, icon, loading}) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const pathnames = location.pathname.split('/').filter(x => x);
    const {jobs, clients} = useContextProvider()
    const role = localStorage.getItem('role');

    const capitalize = (s) => s && s.charAt(0).toUpperCase() + s.slice(1);

    const handleNavigate = (path) => {
        navigate(path);
    }

    const formatBreadcrumb = (pathName, index) => {
        const isLast = index === pathnames.length - 1;
        let formattedName = capitalize(pathName);
        let breadcrumbLink

        jobs.forEach(job => {
            if (job.slug === pathName) {
                formattedName = capitalize(job.name);
                if (pathnames.length > 3) {
                    breadcrumbLink = `/builds/${job.slug}/${job.tags.length > 0 ? job.tags[0].name : "Untagged"}`
                }
            }
        });


        clients.forEach(client => {
            if (client.id === pathName) {
                formattedName = capitalize(client.name);
                if (pathnames.length > 3) {
                    breadcrumbLink = `/client/${client.id}/"}`
                }
            }
        })

        if (["User", "Job", "Build"].includes(formattedName)) {

            if (role === 'superadmin') {
                formattedName = null
            }

            if (role !== 'superadmin') {
                formattedName = formattedName + 's';
                if (formattedName !== 'Builds') {
                    breadcrumbLink = `/${formattedName.toLowerCase()}`
                }
            }
        }

        if (pathnames.length === 4 && !isLast && pathnames.indexOf(pathName) === 2) {
            breadcrumbLink = `/builds/${params.jobSlug}/${pathName}`
        }

        if (((pathName === 'user' && !params.id) || (pathName === 'job' && !params.slug)) && isLast) {
            return (
                <div key={pathName + index} className='item'>
                    <span className='clickable' onClick={() => handleNavigate(breadcrumbLink)}>{formattedName}</span>
                    {role !== 'superadmin' && <Icon icon={breadcrumbArrowIcon}/>}
                    <span className='active'>New</span>
                </div>
            )
        }

        if (pathnames.length === 1 && isLast) {
            return <span key={pathName + index}>{name ? name : formattedName}</span>;
        }

        if (isLast && loading && (params.id || params.slug)) {
            return <div key={pathName + index}>Loading...</div>;
        }

        if (isLast) {
            return <span key={pathName + index}
                         className='active'>{name ? name : formattedName !== capitalize(params.id) && formattedName}</span>;
        }

        return (
            <div key={pathName + index} onClick={() => breadcrumbLink && handleNavigate(breadcrumbLink)}
                 className={classnames('item', {'clickable': breadcrumbLink})}>
                {formattedName}
                {formattedName && <Icon icon={breadcrumbArrowIcon} />}
            </div>
        );

    };

    return (
        <div className='card-header'>
            <div className='header'>
                <div className='breadcrumb'>
                    <Icon icon={icon}/>
                    {pathnames.map((pathName, index) => formatBreadcrumb(pathName, index))}
                </div>
                {onAdd && <Button color='primary' onClick={onAdd}>+ Add New</Button>}
                {onSave && onCancel &&
                    <div className='buttons'>
                        <CancelSaveButtons onSave={onSave} onCancel={onCancel}/>
                    </div>
                }
                {goBack && <Button color='primary' onClick={goBack}>Back</Button>}
            </div>
            {children}
        </div>
    );
}

export default memo(CardHeader, (prevProps, nextProps) => {
    const {name: prevName, ...restPrevProps} = prevProps;
    const {name: nextName, ...restNextProps} = nextProps;

    return Object.entries(restPrevProps).toString() === Object.entries(restNextProps).toString();
});