import React from 'react';
import Modal from "../../common/modals/Modal";
import "./TokensModal.scss"
import Button from "../buttons/Button";
const TokensModal = ({ isOpen, onClose, tokens }) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="md"
            height="fit-content"
            className="token-modal"
        >
            <h3 className="tokens-header">API Tokens</h3>
            <div className="tokens-list">
                {tokens.map((token, index) => (
                    <div key={index} className="token-item">
                        <p><strong>{token.group_name}:</strong></p>
                        <p>{token.api_token}</p>
                        <br />
                    </div>
                ))}
            </div>
            <div className='footer'>
                <Button
                    color="secondary"
                    onClick={() => onClose()}>
                    Close
                </Button>
            </div>
        </Modal>
    );
};

export default TokensModal;
