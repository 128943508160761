import React from "react";
import './TabFilter.scss';

const TabFilter = ({ setInitialTab, initialTab, tabs }) => {
    return (
        <div className="tab-filter">
            {tabs.map(tab => (
                <div
                    className={`tab ${initialTab === tab.name ? "active" : ""}`}
                    key={tab.name}
                    onClick={setInitialTab ? () => setInitialTab(tab.name) : null}
                >
                    {tab.name}
                </div>
            ))}
        </div>
    );
};

export default TabFilter;
