import axios from "axios";

export const loadClients = (params, callback) => {
    const config = {
        url: '/clients',
        params: params
    };


    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const deleteClient = (id, callback) => {
    const config = {
        url: `/clients/${id}`,
        method: 'DELETE'
    }

    axios.request(config).then(
        response => {
            callback();
        }
    )
}

