import React from "react";
import Accordion from "../accordion/Accordion";
import Scenario from "./Scenario";
import "./ReportSection.scss";
import _ from 'lodash';

const ReportSection = ({report}) => {

    let totalPassed = 0;
    let totalMismatch = 0;
    let totalFailed = 0;

    const features = report.features.map(feature => {
        const scenariosPassed = _.sumBy(feature.scenarios, scenario => scenario.status === "PASSED" ? 1 : 0);
        const scenariosMismatch = _.sumBy(feature.scenarios, scenario => scenario.status === "MISMATCH" ? 1 : 0);
        const scenariosFailed = _.sumBy(feature.scenarios, scenario => scenario.status === "FAILED" ? 1 : 0);

        totalPassed += scenariosPassed;
        totalMismatch += scenariosMismatch;
        totalFailed += scenariosFailed;

        return {
            ...feature,
            passed: scenariosPassed,
            mismatch: scenariosMismatch,
            failed: scenariosFailed,
        };
    });

    return <div className='report-section'>
        <div className="header">
            <div className='title'>{report.name}</div>
            <div>Total: {report.total}</div>
            <div className='passed'>Passed: {totalPassed}</div>
            <div className='mismatch'>Mismatch: {totalMismatch}</div>
            <div className='failed'>Failed: {totalFailed}</div>
        </div>
        <div className='body'>
            {features.map((feature, index) => (
                <Accordion
                    title={
                        <>
                            <div className="name">{feature.name}</div>
                            <div>Total: {feature.total}</div>
                            <div className='passed'>Passed: {feature.passed}</div>
                            <div className='mismatch'>Mismatch: {feature.mismatch}</div>
                            <div className='failed'>Failed: {feature.failed}</div>
                        </>
                    }
                    key={feature.name + index}
                >
                    <p className='description'>{feature.description?.split("\n").map(l => <>{l} <br/></>)}</p>
                    {feature.scenarios.map((scenario, idx) => (
                        <Scenario scenario={scenario} key={idx}/>
                    ))}
                </Accordion>
            ))}
        </div>
    </div>

};

export default ReportSection;