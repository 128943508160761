import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './ProfileDropdown.scss';
import * as actions from "./ProfileDropdownActions";
import {userInitials} from "../utils/utils";
import Icon, {logoutIcon, userIcon, settingsIcon} from "../icons/Icon";
import Dropdown from "./Dropdown";
import Avatar from "../avatar/Avatar";
import {useContextProvider} from "../../context/ContextProvider/ContextProvider";
import {useAuth} from "../../context/AuthContext/AuthContext";

const ProfileDropdown = () => {
    const navigate = useNavigate();
    const {logout} = useAuth()
    const [avatar, setAvatar] = useState(null);
    const {profile, setProfile, reloadProfile} = useContextProvider();
    const username = localStorage.getItem('username');
    const role = localStorage.getItem('role');


    const onOptionClicked = (path) => () => {
        if (path === 'logout') {
            logout()
            setProfile(null)
        } else {
            navigate(path);
        }
    };

    useEffect(() => {
        if (!profile) return;
        if (profile.picture_path) {
            actions.downloadAvatar('normal', (data) => {
                setAvatar(URL.createObjectURL(data));
            })
        } else {
            setAvatar(null)
        }

    }, [profile]);

    useEffect(() => {
        reloadProfile();
    }, []);

    return (
        <Dropdown
            className='profile' component={
            <div className='avatar-container'>
                {avatar && <Avatar image={avatar} size='small'/>}
                {!avatar && profile && <div className='initials'>{userInitials(profile.name, profile.surname)}</div>}
                {username}
            </div>
        }
        >
            <ul className="list">
                <li className='item' onClick={onOptionClicked('/profile')}>
                    <Icon icon={userIcon}/> Profile
                </li>
                {role === 'admin' && <li className='item' onClick={onOptionClicked('/settings')}>
                    <Icon icon={settingsIcon}/> Settings
                </li>}
                <li className='item' onClick={onOptionClicked('logout')}>
                    <Icon icon={logoutIcon}/> Logout
                </li>
            </ul>
        </Dropdown>

    );
}

export default ProfileDropdown;
