import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import * as actions from "./GroupActions";
import "./Groups.scss";
import CardHeader from "../../containers/card/CardHeader";
import Card from "../../containers/card/Card";
import { settingsIcon} from "../../common/icons/Icon";
import Loader from "../../common/loader/Loader";
import CardBody from "../../containers/card/CardBody";
import FormField from "../../common/formField/FormField";

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
});

const Group = () => {
    const {id} = useParams()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const initialValues = {
        name: "",
    };

    const saveGroup = (values) => {
        actions.saveGroup(values, () => {
            navigate("/settings");
        });
    };

    const onCancel = () => {
        navigate("/settings");
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: saveGroup,
    });

    useEffect(() => {
        actions.loadGroup(id, (data) => {
            formik.setValues(data);
            setLoading(false);
        });
    }, []);

    return (
        <Card className="group">
            <CardHeader
                name={formik.values.name}
                onCancel={onCancel}
                onSave={formik.handleSubmit}
                icon={settingsIcon}
                loading={loading}
            />
            {loading ? (
                <Loader />
            ) : (
                <CardBody>
                    <form autoComplete="off">
                        <FormField
                            id="name-input"
                            label="Name"
                            name="name"
                            value={formik.values.name}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.name}
                            errors={formik.errors.name}
                        />
                    </form>
                </CardBody>
            )}
        </Card>
    );
};

export default Group;
