import React, { useEffect, useMemo, useState } from "react";
import "./Clients.scss";
import * as actions from "./ClientsActions";
import { useNavigate } from "react-router-dom";
import { userIcon } from "../../common/icons/Icon";
import Table from "../../common/tables/Table";
import CardHeader from "../../containers/card/CardHeader";
import Card from "../../containers/card/Card";
import TableActions from "../../common/buttons/TableActions";
import {useContextProvider} from "../../context/ContextProvider/ContextProvider";

const Clients = () => {
    const navigate = useNavigate();
    const { clients, setClients } = useContextProvider();
    const [loading, setLoading] = useState(true);

    const refreshList = () => {
        actions.loadClients({}, (data) => {
            setClients(data);
            setLoading(false);
        });
    };

    const deleteAction = (client) => {
        actions.deleteClient(client.id, () => refreshList());
    };

    const editAction = (client) => {
        navigate(`/client/${client.id}`);
    };

    const handleAddClient = () => {
        navigate(`/client`);
    };

    useEffect(() => {
        refreshList();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: "Client Name",
                accessor: "name",
                Cell: ({ row }) => (
                    <div className="row-name">
                        <p>{row.original.name}</p>
                    </div>
                ),
            },
            {
                Header: "Actions",
                Cell: ({ row }) => (
                    <TableActions
                        row={row.original}
                        name="Client"
                        onEdit={editAction}
                        onDelete={deleteAction}
                    />
                ),
            },
        ],
        [clients]
    );

    return (
        <Card className="clients">
            <CardHeader onAdd={handleAddClient} icon={userIcon} />
            <Table columns={columns} data={clients} loading={loading} />
        </Card>
    );
};

export default Clients;
