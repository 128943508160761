import React from "react";
import * as classnames from "classnames";
import './Icon.scss';

import {ReactComponent as Delete} from "./icon/delete.svg";
import {ReactComponent as Edit} from "./icon/edit.svg";
import {ReactComponent as Show} from "./icon/show.svg";
import {ReactComponent as Eye} from "./icon/eye.svg";
import {ReactComponent as EyeSlash} from "./icon/eye-slash.svg";
import {ReactComponent as ChevronDown} from "./icon/chevron-down.svg";
import {ReactComponent as UserGroup} from "./icon/user-group.svg";
import {ReactComponent as Job} from "./icon/job.svg";
import {ReactComponent as Build} from "./icon/build.svg";
import {ReactComponent as BreadcrumbArrow} from "./icon/arrow-right-breadcrumb.svg";
import {ReactComponent as User} from "./icon/user.svg";
import {ReactComponent as Settings} from "./icon/settings.svg";
import {ReactComponent as Logout} from "./icon/logout.svg";
import {ReactComponent as Magnifier} from "./icon/magnifier.svg";

export const editIcon = {svg: Edit, name: "edit"};
export const deleteIcon = {svg: Delete, name: "delete"};
export const showIcon = {svg: Show, name: "show"};
export const eyeIcon = {svg: Eye, name: "eye"};
export const eyeSlashIcon = {svg: EyeSlash, name: "eye-slash"};
export const chevronDown = {svg: ChevronDown, name: "chevron-down"};
export const userGroupIcon = {svg: UserGroup, color: true, name: "user-group"};
export const jobIcon = {svg: Job, name: "job-icon"};
export const buildIcon = {svg: Build, name: "build-icon"};
export const breadcrumbArrowIcon = {svg: BreadcrumbArrow, name: "breadcrumb-arrow"};
export const userIcon = {svg: User, name: "user-icon"};
export const settingsIcon = {svg: Settings, name: "settings-icon"};
export const logoutIcon = {svg: Logout, name: "logout-icon"};
export const magnifierIcon = {svg: Magnifier, name: "magnifier-icon"};



const Icon = ({icon, id, className}) => {
    return <div className={classnames("icon", className, {color: icon.color}, icon.name)}
                id={id}>{React.createElement(icon.svg)}</div>
}

export default Icon;