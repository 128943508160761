import React, {useEffect, useState} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import * as actions from "./ClientActions";
import "./Client.scss";
import CardHeader from "../../containers/card/CardHeader";
import Card from "../../containers/card/Card";
import Table from "../../common/tables/Table";
import Loader from "../../common/loader/Loader";
import FormField from "../../common/formField/FormField";
import { userIcon } from "../../common/icons/Icon";
import TableActions from "../../common/buttons/TableActions";
import TabFilter from "../../common/tab/TabFilter";

const validationSchema = Yup.object({
    name: Yup.string().required("Client name is required"),
});

const Client = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = React.useState(false);
    const [users, setUsers] = React.useState([]);
    const [tab, setTab] = useState("Client")
    const tabs = [
        { name: "Client" },
        { name: "Users" },
    ]

    const initialValues = {
        name: "",
    };

    const refreshList = () => {
        if (id) {
            setLoading(true);
            actions.loadClient(id, (data) => {
                formik.setValues(data);
                setUsers(data.users || []);
                setLoading(false);
            });
        }
    };

    const saveClient = (values) => {
        actions.saveClient(values, () => {
            navigate("/clients");
        });
    };

    const onCancel = () => {
        navigate("/clients");
    };

    const deleteUser = (user) => {
        actions.deleteUser(user.id, () => {
            setUsers((prevUsers) =>
                prevUsers.filter((u) => u.id !== user.id)
            );
        });
    };

    const editUser = (user) => {
        navigate(`user/${user.id}`);
    };

    const addUser = () => {
        navigate(`user`);
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: saveClient,
    });

    useEffect(() => {
        refreshList();
    }, [id]);

    useEffect(() => {
        if (location.state && location.state.tab) {
            setTab(location.state.tab);
        }
    }, [location.state]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Username",
                accessor: "username",
                Cell: ({ row }) => (
                    <div className="row-username">
                        <p>{row.original.username}</p>
                    </div>
                ),
            },
            {
                Header: 'Jenkins Username',
                accessor: 'jenkins_username'
            },
            {
                Header: 'Jenkins Token',
                accessor: 'jenkins_token'
            },
            {
                Header: 'Role',
                accessor: 'role'
            },
            {
                Header: "Actions",
                Cell: ({ row }) => (
                    <TableActions
                        row={row.original}
                        name="User"
                        onEdit={editUser}
                        onDelete={deleteUser}
                    />
                ),
            },
        ],
        [users]
    );
    return (
        <Card className="client">
            <CardHeader
                name={formik.values.name}
                onCancel={onCancel}
                onSave={tab === "Client" && formik.handleSubmit}
                onAdd={tab === "Users" && addUser}
                icon={userIcon}
                loading={loading}
            >
                <TabFilter
                    setInitialTab={id && setTab}
                    initialTab={tab}
                    tabs={tabs}
                />
            </CardHeader>
            {loading ? (
                <Loader />
            ) : (
                <>
                    {tab === "Client" ? (
                        <form autoComplete="off">
                            <FormField
                                id="name-input"
                                label="Client Name"
                                name="name"
                                value={formik.values.name}
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                touched={formik.touched.name}
                                errors={formik.errors.name}
                            />
                        </form>
                    ) : (
                        <div className="users-section">
                            <Table
                                columns={columns}
                                data={users}
                            />
                        </div>
                    )}
                </>
            )}
        </Card>
    );
};

export default Client;
